import * as React from "react"
import { Helmet } from "react-helmet"

import {
  Header,
  Hero,
  Landing,
  CarouselPrimary,
  Exercise,
  CarouselSecondary,
  Footer,
  BottomNavbar,
  PdfDownload,
} from "../components/index"
import SubTitle from "../components/Common/SubTitle/SubTitle"
import Container from "../components/Common/Container/Container"

import mockup from "../utils/data"

const IndexPage = () => {
  return (
    <div>
      <Helmet>
        <title>Esteekey - Home Page</title>
        <meta name="description" content="Esteekey - #1 Fitness app for 2022" />
        <meta name="theme-color" content="#ffffff" data-react-helmet="true" />
      </Helmet>
      <Header />
      {/* <Hero /> */}
      <Landing />
      {/* <CarouselPrimary list={mockup.primary} /> */}
      {/* <Exercise list={mockup.exercise} title="Exercise" />
      <CarouselSecondary
        list={mockup.secondary}
        subtitle="Listen to our podcast"
        link="podcast"
      />
      <CarouselSecondary
        list={mockup.secondary2}
        dark
        title1="Meditations"
        title2=""
        subtitle="Join and meditate with us"
        link="meditations"
      />
      <div className="mt-6 xl:mt-24 mb-12  mx-4 sm:mx-0 ">
        <Container>
          <SubTitle
            title1="PDF"
            title2="Downloads"
            text="Some description for the downloadable files here. Lorem ipsum dolor sit amet, consectetur
adipiscing elit. Tincidunt massa in venenatis lectus elit. Dolor ipso sit amet."
          />
        </Container>
      </div>
      <PdfDownload list={mockup.pdf_download} />
      <Exercise list={mockup.videos} title="Videos" /> */}
      <BottomNavbar activePage="home" />
      <Footer />
    </div>
  )
}

export default IndexPage
